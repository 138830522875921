<script>
import Vue from 'vue'
import HistoryOrder from '@/components/pages/restaurantAdmin/HistoryOrder'
import RestApiType from '@/api/RestApiType'
import DataIterator from '@/components/table/DataIterator'
import ExportOrders from '@/components/pages/restaurantAdmin/ExportOrders'

export default {
    components: { ExportOrders, DataIterator, HistoryOrder },
    data() {
        return {
            loading: false,
            api: RestApiType.ORDERS,
            filters: {},
            history: [
                { title: this.$t('labels.today'), param: 'today' },
                { title: this.$t('labels.yesterday'), param: 'yesterday' },
                { title: this.$t('labels.thisweek'), param: 'thisweek' },
                { title: this.$t('labels.lastweek'), param: 'lastweek' },
                { title: this.$t('labels.thismonth'), param: 'thismonth' },
                { title: this.$t('labels.lastmonth'), param: 'lastmonth' },
            ],
        }
    },
    created() {
        this.setFilters(this.$route.params.history)
    },
    methods: {
        setFilters(val) {
            this.$set(this, 'filters', this.getFilters(val))
        },
        getFilters(param) {
            let now = Vue.prototype.$date()
            switch (param) {
                case 'today':
                    return {
                        activeOrders: false,
                        requiredDeliveryTime: {
                            after: now.startOf('day').format(),
                            before: now.endOf('day').format(),
                        },
                    }
                case 'yesterday':
                    return {
                        activeOrders: false,
                        requiredDeliveryTime: {
                            after: now
                                .subtract(1, 'day')
                                .startOf('day')
                                .format(),
                            before: now
                                .subtract(1, 'day')
                                .endOf('day')
                                .format(),
                        },
                    }
                case 'thisweek':
                    return {
                        activeOrders: false,

                        requiredDeliveryTime: {
                            after: now
                                .isoWeekday(1)
                                .startOf('day')
                                .utc()
                                .format(),
                            before: now
                                .isoWeekday(7)
                                .endOf('day')
                                .utc()
                                .format(),
                        },
                    }
                case 'lastweek':
                    return {
                        activeOrders: false,
                        requiredDeliveryTime: {
                            after: now
                                .subtract(1, 'week')
                                .isoWeekday(1)
                                .startOf('day')
                                .format(),
                            before: now
                                .subtract(1, 'week')
                                .isoWeekday(7)
                                .endOf('day')
                                .format(),
                        },
                    }
                case 'thismonth':
                    return {
                        activeOrders: false,
                        requiredDeliveryTime: {
                            after: now.startOf('month').format(),
                            before: now.endOf('month').format(),
                        },
                    }
                case 'lastmonth':
                    return {
                        activeOrders: false,

                        requiredDeliveryTime: {
                            after: now
                                .subtract(1, 'month')
                                .startOf('month')
                                .format(),
                            before: now
                                .subtract(1, 'month')
                                .endOf('month')
                                .format(),
                        },
                    }
            }
        },
    },
}
</script>

<template lang="pug">
v-row
    v-col(cols="12" md="auto")
        v-card(:elevation="0").history-drawer
            v-card-text
                v-navigation-drawer(permanent floating)
                    v-list-item(v-for='(item, index) in history' active-class="pointer-none" :to='{params:{history: item.param, id: undefined}}' @click="setFilters(item.param)" tag="div" color="g-26" :key='item.title' exact-active-class="active")
                        v-list-item-content
                            v-list-item-title {{ item.title }}
    v-col(cols="auto").d-none.d-md-block
    v-col
        data-iterator.history-wrapper(:api="api" :filters.sync="filters" :fixedItemsPerPage="10")
            template(slot='noItems') No order
            template(slot-scope="{ items }")
                v-card(:elevation="0" v-if="items && items.length > 0")
                    v-card-text
                        history-order(v-for="(order, index) in items" :order="order" :key="`historyOrder-${order.identifier}`" :style="{transitionDelay: `${index * 200}ms !important`, transitionDuration: '.6s !important'}")
                            template(v-slot:export)
                                export-orders.ma-1(:orders="items")
</template>

<style lang="scss">
.history-wrapper {
    max-width: var(--manage-order-width);
}
</style>
