<script>
import OrderItem from '@/components/pages/restaurantAdmin/OrderItem'
import { mapGetters } from 'vuex'
import canteenType from '@/store/type/canteenType'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'

export default {
    components: { OrderItem },
    computed: {
        ...mapGetters({
            order: canteenType.getters.CANTEEN,
        }),
    },
    watch: {
        '$route.params': {
            immediate: true,
            deep: true,
            handler(params) {
                if (params && params.id && params.restaurant) {
                    RestApiCollection.get(
                        RestApiType.RESTAURANT_ORDER_CANTEENS
                    ).get(params.restaurant, params.id)
                }
            },
        },
        order: {
            deep: true,
            handler(order, orderBefore) {
                if (
                    !order &&
                    this.$route.params?.id === orderBefore.identifier
                ) {
                    this.$router.replace({
                        params: {
                            ...this.$route.params,
                            ...{ id: undefined, restaurant: undefined },
                        },
                    })
                }
            },
        },
    },
}
</script>

<template lang="pug">
v-card.open-order(v-if="order" :elevation="0")
    v-card-text
        v-row
            v-col
                .d-flex.flex-column.align-start
                    .text-h6.font-weight-bold {{'#'}}{{ order.identifierForRestaurant }}
                    .text-body-2.g-73--text {{ $t('orders.cutoff') }}:&nbsp;
                        span(:inner-html.prop="order.cutoffTime | toSchedule")
            v-col(cols="auto")
                template(v-if="order.mealCount")
                    .d-flex.flex-column.align-center
                        .font-weight-bold.text-h6 {{ $t('orders.pickup_at') }}&nbsp;
                            span(:inner-html.prop="order.startDeliveryAt  | toSchedule")
                    .text-body-2.g-73--text.text-center {{ order.startDeliveryAt  | toDate}}
                .text-h5(v-else) {{ $t('orders.no_orders') }}
            v-col
                .d-flex.flex-column.align-end
                    .text-h6.font-weight-bold {{ order.total | currency }}
                    .text-body-2.g-73--text {{ $tc('orders.items', order.mealCount, { count: order.mealCount }) }}&nbsp;{{ $t('orders.so_far') }}

        .order-item-wrapper
            order-item(v-for="(meal, index) in order.meals" :model="meal" :key="`openOrderMeal-${index}`" cc hide-cc-labels)



</template>

<style scoped lang="scss">
.open-order {
    max-width: var(--manage-order-width);
}
</style>
