<template lang="pug">
v-row
    v-col(cols="12" md="3" lg="4" xl="3")
        v-card(:elevation="0")
            v-card-text
                order-list(:quantity="confirmedOrders.length" :placeholder="$t('orders.no_item')") {{ $t('orders.preparing') }}
                    template(slot="content")
                        order-list-item(v-for="(item, index) in confirmedOrders" :order="item" :key="`list-item-new-${index}-${item.id}`")
        v-card(:elevation="0").mt-4
            v-card-text
                order-list(:quantity="approvedOrders.length" :placeholder="$t('orders.no_item')") {{ $t('orders.scheduled') }}
                    template(slot="content")
                        order-list-item(v-for="(item, index) in approvedOrders" :order="item" :key="`list-item-new-${index}-${item.id}`")
    v-col(cols="auto").d-none.d-md-block
    v-col
        active-order(main :first-order="firstOrder")


</template>

<script>
import ApproveOrder from '@/components/pages/restaurantAdmin/confirm/ApproveOrder'
import ConfirmOrder from '@/components/pages/restaurantAdmin/confirm/ConfirmOrder'
import OrderListItem from '@/components/pages/restaurantAdmin/OrderListItem'
import ActiveOrder from '@/components/pages/restaurantAdmin/ActiveOrder'
import OrderList from '@/components/pages/restaurantAdmin/OrderList'
import PageTitle from '@/components/layout/components/pageTitle'
import configType from '@/store/type/orderType'
import RestApiType from '@/api/RestApiType'
import { mapGetters } from 'vuex'

export default {
    components: {
        PageTitle,
        ConfirmOrder,
        ApproveOrder,
        ActiveOrder,
        OrderListItem,
        OrderList,
    },
    data() {
        return {
            api: RestApiType.ORDERS,
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            approvedOrders: configType.getters.APPROVED_ORDERS_LIST,
            confirmedOrders: configType.getters.CONFIRMED_ORDERS_LIST,
        }),
        firstOrder() {
            return this.confirmedOrders[0] || this.approvedOrders[0]
        },
    },
}
</script>
