<template lang="pug">
router-link.order-list-item.d-flex.justify-space-between(
    v-if="order && !expired"
    tag="div"
    :to="{params: {id: isCanteen ? order.identifier : order.id, restaurant: isCanteen ? order.restaurantId : undefined}}"
    active-class="active")
    .d-flex.flex-column.justify-center.w-100
        .d-flex.align-start.flex-wrap.w-100
            .d-flex.align-center.justify-space-between.w-100
                span.font-weight-medium.text-body-2.mr-1 {{'#'}}{{order.identifierForRestaurant}}
                span.text-right(v-if="isPreparing && getTimeText" :class="`${getTimeText.color}--text`") {{ getTimeText.text  }}
            v-chip.font-weight-bold(v-if="isPickup && isPreparing && !isCanteen" color="blue" label small dark) {{ $t('orders.pickup_by_stuart') }}
            v-chip.font-weight-bold(v-if="restaurantManaged && isPreparing && !isCanteen" color="blue" label small dark) {{ $t('orders.delivery_by_you') }}
            v-chip.font-weight-bold(v-if="!isPreparing && !isCanteen" color="warning" label small dark) {{ $t('orders.scheduled') }}
            .font-weight-medium.text-body-2.mr-1.w-100(v-if="isMultiRestaurantAdmin") {{order.restaurantName}}
            .d-flex.justify-space-between.flex-wrap.w-100(v-if="order.cutoffTime")
                div(v-if="order.cutoffTime")
                    span.font-weight-medium {{ $t('orders.cutoff') }}:!{' '}
                    span(:inner-html.prop="order.cutoffTime | toSchedule")
        .d-flex.align-center.flex-wrap
            span.font-weight-medium.text-body-2(v-if="itemsCount" :class="{'mr-2': !isCanteen}") {{ $tc('orders.items', itemsCount, { count: itemsCount }) }}
                template(v-if="isCanteen") &nbsp;{{ $t('orders.so_far') }}
            .flex-break(v-if="isCanteen")
            span.text-body-2.g-73--text {{ price | currency}}
    .d-flex.justify-end.ml-1(v-if="!isPreparing || !getTimeText" :class="isCanteen ? 'align-start' : 'align-center'")    
        .d-flex.flex-column(v-if="isCanteen")    
            |  {{ $t('orders.cutoff')}}
            .d-flex.flex-column.align-end.justify-center.text-body-2.g-73--text(:inner-html.prop="order.cutoffTime | toSchedule")   
        .d-flex.flex-column.align-end.justify-center.text-body-2.g-73--text(v-else :inner-html.prop="deadline | toSchedule")
        
</template>

<script>
import authType from '@/store/type/authType'
import Delivery from '@/mixins/Delivery'
import { mapGetters } from 'vuex'

export default {
    mixins: [Delivery],
    props: {
        order: null,
        active: Boolean,
    },
    data() {
        return {
            expired: false,
        }
    },
    computed: {
        ...mapGetters({
            isMultiRestaurantAdmin: authType.getters.IS_MULTI_RESTAURANT_ADMIN,
        }),
        status() {
            return this.order
        },
        isPickup() {
            return this.order?.isPickup
        },
        isNew() {
            return this.status.key === 'new' && !this.open
        },
        itemsCount() {
            return this.isCanteen
                ? this.order?.mealCount
                : this.order?.mealsQuantity
        },
        isSupplierPriced() {
            return (
                this.order?.supplierPriceSumItems !== this.order?.priceSumItems
            )
        },
        price() {
            return this.isCanteen
                ? this.order.total
                : this.restaurantManaged
                ? parseFloat(this.order?.supplierPriceSumItems) +
                  parseFloat(this.order?.priceDelivery)
                : this.order?.supplierPriceSumItems
        },
    },
}
</script>

<style lang="scss">
.order-list-item {
    border-top: 1px solid var(--v-g-cc-base);
    padding: 15px 10px;
    transition: background-color var(--transition-speed);
    &.active,
    &:hover {
        background-color: var(--v-g-f0-base);
    }
}
</style>
