<script>
import ExportOrders from '@/services/Orders/ExportOrders'
import cloneDeep from 'lodash/cloneDeep'

export default {
    props: {
        orders: null,
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        exportOrders() {
            let query = cloneDeep(this.$route.query)
            delete query.page
            delete query.itemsPerPage
            this.loading = true
            const exportParams = {}
            for (const [key, value] of Object.entries(query)) {
                if (typeof value === 'object') {
                    for (const [skey, svalue] of Object.entries(value)) {
                        exportParams[`${key}[${skey}]`] = svalue
                    }
                } else {
                    exportParams[key] = value
                }
            }
            ExportOrders(exportParams).finally(() => {
                this.loading = false
            })
        },
    },
}
</script>

<template lang="pug">
v-btn(color="primary" @click="exportOrders" v-if="orders && orders.length > 0" small)
    v-fade-transition(mode="out-in")
        v-progress-circular.mr-1(v-if="loading" :size="15" :width="1" color="white" indeterminate)
        v-icon.mr-1(small v-else) cloud_download
    | {{ $t('actions.export') }}
</template>
